<template>
<div>
    <div @click="showRecord" class="bound-record">
        <slot></slot>
    </div>
    <a-modal :title="type == 1 ?'入库记录':'出库记录'"  width="700px"
             v-model:visible="brState.show" :footer="null">
        <a-table :pagination="false" rowKey="id" :data-source="brState.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'操作日期',dataIndex:'created_at'},
            {title:'操作类型',dataIndex:'type',slots:{customRender:'type'}},
            {title:'操作说明',dataIndex:'content',width:300},
        ]">
            <template #type="{record}">
                <a-tag :color="record.type == 1 ?'#00CC66':'#0099FF'">
                    {{ record.type === 1 ?'入库':'出库'}}
                </a-tag>
            </template>
        </a-table>
    </a-modal>
</div>
</template>

<script>
import { useBoundRecord } from '@/models/addons/storage'
import {watch} from "vue";
export default {
    name: "com-bound-record",
    props:{
        id:{
            type:Number
        },
        type:{      //1入库 2出库
            type:Number
        }
    },
    setup(props,context){
        let { brState,getBoundRecordList } = useBoundRecord()
        brState.search.id = props.id
        brState.search.type = props.type

        watch(()=>props.id,value=>{
            brState.search.id = value
        },{deep:true})
        watch(()=>props.type,value=>{
            brState.search.type = value
        },{deep:true})

        function showRecord(){
            getBoundRecordList(1,brState.limit)
            brState.show = true
        }

        return { brState,getBoundRecordList ,showRecord}
    }
}
</script>

<style scoped lang="scss">
</style>
