<template>
<div>
    <a-card>
        <template #title>
            <router-link to="/adhibition/storage/inbound_edit">
                <a-button type="primary" v-has="{action:'/adhibition/storage/inbound_edit',plat:0}">
                    <i class="ri-add-line ri-top"></i>新增入库
                </a-button>
            </router-link>
        </template>
        <template #extra>
            <a-input-search
                v-model:value="ibState.search.key"
                placeholder="入库单据 / 产品名称 / 规格型号"
                enter-button
                style="width:400px"
                @search="getInBoundRecord(1,ibState.limit)"
            />
        </template>

        <a-table :pagination="false" rowKey="id" :data-source="ibState.list" :columns="[
            {title:'单据编号',dataIndex:'id'},
            {title:'单据日期',dataIndex:'document_date'},
            {title:'入库类型',dataIndex:'class',slots:{customRender: 'class'}},
            {title:'图片',dataIndex:'classInfo.images',slots:{customRender: 'images'}},
            {title:'产品名称',dataIndex:'classInfo.name'},
            {title:'规格型号',dataIndex:'classInfo.model',slots:{customRender: 'model'}},
            {title:'所属仓库',dataIndex:'depot.name'},
            {title:'入库数量',dataIndex:'amount'},
            {title:'备注',dataIndex:'remark'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]">
            <template #class="{record}">
                <span v-if="record.class === 1 ">产品入库</span>
                <span v-if="record.class === 2 ">归还入库</span>
                <span v-if="record.class === 3 ">农资入库</span>
            </template>
            <template #images="{record}">
                <a-image :src=" record.classInfo.images[0]" width="50px"
                         v-if="record.classInfo && record.classInfo.images && record.classInfo.images.length > 0">
                </a-image>
            </template>
            <template #model="{record}">
                <span v-if="record.classInfo && record.classInfo.model">{{record.classInfo.model}}</span>
                <span v-else>--</span>
            </template>
            <template #action="{record}">
                <kd-button title="编辑" icon="ri-edit-line" @click="showUpdateBound(record.id)"
                           v-has="{action:'/adhibition/storage/inbound_edit',plat:0}">
                </kd-button>
                <kd-button title="查看凭证"
                           icon="ri-profile-line"
                           type="success"
                           @click="showVoucher(record.voucher)"
                           v-has="{action:'wxapp_storage_inbound_look',plat:0}">
                </kd-button>
                <com-bound-record :id="record.id" :type="1" style="display: inline-block;">
                    <kd-button title="操作记录" icon="ri-file-list-3-line" type="purple"
                               v-has="{action:'wxapp_storage_inbound_record',plat:0}">
                    </kd-button>
                </com-bound-record>
            </template>
        </a-table>
        <kd-pager :page-data="ibState" :event="getInBoundRecord"></kd-pager>
    </a-card>
    <a-modal title="查看凭证"  width="500px" v-model:visible="vState.show" :footer="null" :bodyStyle="{textAlign:'center'}">
        <a-image :src="vState.url" width="400px"></a-image>
    </a-modal>

    <a-modal title="入库记录编辑"  width="800px" v-model:visible="ubState.show" @ok="saveUpdateBound">
        <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
            <a-form-item label="入库方式">
                <a-select v-model:value="ubState.form.class" placeholder="选择入库方式" disabled>
                    <a-select-option :value="1">产品入库</a-select-option>
                    <a-select-option :value="2">归还入库</a-select-option>
                    <a-select-option :value="3">农资入库</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="产品信息">
                <a-space>
                    <span>{{ ubState.form.name}}</span>
                    <div v-if="ubState.form.productInfo.sku && ubState.form.productInfo.sku.length >0">
                        <a-select v-model:value="ubState.form.sku_id" style="width: 200px" placeholder="选择规格">
                            <a-select-option :value="item.id" v-for="(item,index) in ubState.form.productInfo.sku" key="index">
                                {{ item.model }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <template v-else>
                        <span v-if="ubState.form.productInfo.model">--{{ ubState.form.productInfo.model}}</span>
                    </template>
                </a-space>

            </a-form-item>
            <a-form-item label="入库数量">
                <a-input v-model:value="ubState.form.amount" type="number"></a-input>
            </a-form-item>
            <a-form-item label="单价">
                <a-input v-model:value="ubState.form.unit_price" addon-after="元"></a-input>
            </a-form-item>
            <a-form-item label="存入仓库">
                <a-select v-model:value="ubState.form.depot_id" placeholder="选择仓库">
                    <a-select-option :value="item.id" v-for="(item,index) in ubState.enterPot" :key="index">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <div v-if="ubState.form.class === 2 ">
                <a-form-item label="返还人">
                    <a-input v-model:value="ubState.form.returnee"></a-input>
                </a-form-item>
                <a-form-item label="物品状态">
                    <a-select v-model:value="ubState.form.loss" placeholder="物品状态">
                        <a-select-option :value="1">完好无损</a-select-option>
                        <a-select-option :value="2">轻微损耗</a-select-option>
                        <a-select-option :value="3">中度损耗</a-select-option>
                        <a-select-option :value="4">严重损耗</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="详细描述">
                    <a-textarea v-model:value="ubState.form.desc" placeholder="详细描述" :rows="4" />
                </a-form-item>
            </div>

            <a-form-item label="上传凭证">
                <kd-img-select :src="ubState.form.voucher" @success="e=>ubState.form.voucher = e"></kd-img-select>
            </a-form-item>
            <a-form-item label="备注">
                <a-textarea v-model:value="ubState.form.remark" placeholder="备注" :rows="4" />
            </a-form-item>

        </a-form>
    </a-modal>
</div>
</template>

<script>
import comBoundRecord from './com-bound-record.vue'
import { useInBound,useVoucher,useUpdateBound } from '@/models/addons/storage'
export default {
    components:{
        comBoundRecord
    },
    setup(){
        //入库记录
        let { ibState,getInBoundRecord } = useInBound()

        //查看凭证
        let { vState,showVoucher } = useVoucher()
        //编辑入库记录
        let { ubState,showUpdateBound ,sureSelectProduct,saveUpdateBound} = useUpdateBound(ibState,getInBoundRecord )

        getInBoundRecord(1,ibState.limit)

        return {
            ibState,getInBoundRecord,vState,showVoucher,sureSelectProduct,
            ubState,showUpdateBound,saveUpdateBound
        }
    }
}
</script>

<style scoped>

</style>
